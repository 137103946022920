class Stopwatch {
  constructor() {
    this.isRunning = false;
    this.startTime = 0;
    this.elapsedTime = 0;
    this.intervalId = null;
    this.minutesElement = document.getElementById('minutes');
    this.secondsElement = document.getElementById('seconds');
    this.millisecondsElement = document.getElementById('milliseconds');
    this.startStopBtn = document.getElementById('start-stop-btn');
  }

  startStop() {
    if (!this.isRunning) {
      this.start();
      this.startStopBtn.textContent = this.startStopBtn.getAttribute('data-stop-text');
    } else {
      this.stop();
      this.startStopBtn.textContent = this.startStopBtn.getAttribute('data-start-text');
    }
  }

  start() {
    if (!this.isRunning) {
      this.isRunning = true;
      this.startTime = Date.now() - this.elapsedTime;
      this.intervalId = setInterval(() => {
        this.elapsedTime = Date.now() - this.startTime;
        this.displayTime();
      }, 10);
    }
  }

  stop() {
    if (this.isRunning) {
      this.isRunning = false;
      clearInterval(this.intervalId);
    }
  }

  displayTime() {
    const formattedTime = this.formatTime(this.elapsedTime);
    const [minutes, seconds, milliseconds] = formattedTime.split(':');
    this.minutesElement.textContent = minutes;
    this.secondsElement.textContent = seconds;
    this.millisecondsElement.textContent = milliseconds;
  }

  formatTime(milliseconds) {
    const minutes = Math.floor(milliseconds / (60 * 1000));
    const seconds = Math.floor((milliseconds % (60 * 1000)) / 1000);
    const ms = Math.floor((milliseconds % 1000) / 10);

    return `${this.padZero(minutes)}:${this.padZero(seconds)}:${this.padZero(ms)}`;
  }

  // eslint-disable-next-line class-methods-use-this
  padZero(value) {
    return value < 10 ? `0${value}` : value;
  }
}

const startStopBtn = document.getElementById('start-stop-btn');

if (startStopBtn) {
  const stopwatch = new Stopwatch();

  startStopBtn.addEventListener('click', () => {
    stopwatch.startStop();
  });
}
